// ** @jsx jsx *
import React from "react"
import { jsx, Text } from "theme-ui"
import Layout from "../components/Layout"
import Header from "../components/ui/Header"
import Body from "../components/productTemplateComponents/Body"

const PageTemplate = ({ pageContext }) => {
  const { page } = pageContext
  console.log(page)
  return (
    <Layout
      seo={{
        title: page.title,
        description: page.description,
      }}
    >
      <Header />
      <br />
      <div
        sx={{
          variant: "div.flex",
          justifyContent: "center",
          marginTop: 4,
          width: "100%",
        }}
      >
        <div sx={{ maxWidth: ["width1", "width2", "width3"] }}>
          {page.title && (
            <div
              sx={{
                width: "100%",
                backgroundColor: "backgroundSecondary",
                padding: 2,
                borderRadius: 0,
              }}
            >
              {page.title && (
                <Text
                  variant="title"
                  sx={{ textTransform: "uppercase", textAlign: "center" }}
                >
                  {page.title}
                </Text>
              )}
              <br />
              {page.description && (
                <Text variant="body" sx={{ textAlign: "center" }}>
                  {page.description}
                </Text>
              )}
            </div>
          )}
          <div>
            {page.blockPage &&
              page.blockPage.length > 0 &&
              page.blockPage.map(block => {
                return <BlockPage block={block} />
              })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

const BlockPage = ({ block }) => {
  const validateBlock = block && block._type ? true : false || false
  return (
    validateBlock && (
      <div>{block._type == "bodyText" && <Body document={block} />}</div>
    )
  )
}

export default PageTemplate
